
import {
  defineComponent,
  ref,
  reactive,
  type PropType
} from 'vue';
import {
  storeToRefs
} from 'pinia';
import {
  SButton,
  SInput,
  SModal
} from '@strana-artw/ui-kit';
import {
  required,
  maxLength,
  minLength
} from '@vuelidate/validators';
import {
  getMeFromStore
} from '@@/common/services/user';
import {
  PolicyOutput
} from '@strana-artw/cabinets-common';
import { useVuelidate } from '@vuelidate/core';
import getErrorMessageTyped from '@@/common/services/getErrorMessageForNotificationDataTyped';
import {
  nameValidator,
  phoneValidator
} from '@/shared/lib/utils/validators';
import {
  useAppealStore,
  APPEAL_TYPE
} from '@/store/appeal';
import { useCitiesStore } from '@/store/cities';
import { ENV_VARIABLES } from '@/app/config/environment';
import {
  useBaseForm,
  type ServerErrors
} from '@/shared/@forms/composables/useBaseForm';
import type { AppealType } from '@/types/Appeal';
import FavoritesModalBuildingInfo from '@/components/pages/favorites/FavoritesModalBuildingInfo.vue';

interface State {
  form: {
    fullName: string | null;
    phone: string | null;
  }
}

export default defineComponent({
  name: 'CallbackModalForm',

  components: {
    FavoritesModalBuildingInfo,
    PolicyOutput,
    SButton,
    SInput,
    SModal
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    type: {
      type: String as PropType<AppealType>,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: undefined
    },

    cardTitle: {
      type: String,
      default: undefined
    }
  },

  setup () {
    const appealStore = useAppealStore();
    const citiesStore = useCitiesStore();

    const { realtyForFavoritesFreeBooking } = storeToRefs(appealStore);
    const { currentCity } = storeToRefs(citiesStore);

    const { getCurrentCity } = citiesStore;
    const {
      sendAppeal,
      resetRealtyForFavoritesFreeBooking
    } = appealStore;

    const serverErrors = ref<ServerErrors>({});

    const hasServerError = (field: string): boolean => {
      return Boolean(serverErrors.value[field]);
    };

    const state: State = reactive({
      form: {
        fullName: null,
        phone: null
      }
    });

    const rules = {
      form: {
        fullName: {
          required,
          alpha: nameValidator,
          minLength: minLength(2),
          maxLength: maxLength(35),
          serverError () {
            return !hasServerError('fullName');
          }
        },

        phone: {
          isPhone: phoneValidator,
          required,
          minLength
        }
      }
    };

    const v$ = useVuelidate(rules, state);

    const {
      getError
    } = useBaseForm({
      v$,
      serverErrors: serverErrors.value
    });

    return {
      v$,
      state,
      realtyForFavoritesFreeBooking,
      currentCity,
      getCurrentCity,
      sendAppeal,
      resetRealtyForFavoritesFreeBooking,
      getError
    };
  },

  data () {
    return {
      isLoading: false
    };
  },

  async fetch () {
    if (!this.currentCity) {
      await this.getCurrentCity();
    }
  },

  computed: {
    ENV_VARIABLES () {
      return ENV_VARIABLES;
    },

    displayedDescription () {
      return this.description || this.$t('description');
    },

    isFreeBooking () {
      return [
        APPEAL_TYPE.FAVORITES_FREE_BOOKING,
        APPEAL_TYPE.OFFER_FREE_BOOKING
      ].includes(this.type);
    },

    isOfferFreeBooking () {
      return this.type === APPEAL_TYPE.OFFER_FREE_BOOKING;
    },

    offerId () {
      return Number(this.$route.params.id);
    }
  },

  watch: {
    open (value) {
      if (!value) {
        this.resetRealtyForFavoritesFreeBooking();

        return;
      }

      const {
        name,
        surname,
        phone
      } = this.getMeFromStore();

      this.state.form.fullName = [name, surname].filter(Boolean).join(' ');
      this.state.form.phone = phone;
    }
  },

  methods: {
    getMeFromStore,

    handlePhoneInput (value: string) {
      this.state.form.phone = value.replace('+7 8', '+7 (');
    },

    cancel () {
      this.closeModal();
    },

    async confirm () {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }

      const appeal = {
        name: this.state.form.fullName,
        phone: this.state.form.phone,
        type: this.type,
        card_title: this.cardTitle
      };

      const additional = this.isFreeBooking
        ? {
          profitBaseId: this.realtyForFavoritesFreeBooking?.pk,
          ...(this.isOfferFreeBooking && { offerCollectionId: this.offerId })
        }
        : undefined;

      try {
        this.isLoading = true;
        await this.sendAppeal({
          ...appeal,
          ...(additional && { additional })
        });
        this.$emit('confirm');
        this.closeModal();
      } catch (error) {
        console.error('🚀 ~ file: CallbackModalForm.vue ~ confirm ~ error', error);
        const errorMessage = getErrorMessageTyped(error);
        this.$notify.create({
          message: errorMessage,
          type: 'negative'
        });
        this.$sentry.captureException(error);
      } finally {
        this.isLoading = false;
      }
    },

    resetForm () {
      this.v$.$reset();
      this.state.form = {
        fullName: null,
        phone: null
      };
    },

    closeModal () {
      this.resetForm();
      this.$emit('update:open', false);
    }
  }
});
