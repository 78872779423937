
import {
  defineComponent,
  type PropType
} from 'vue';
import CallbackModalForm from '@/components/common/modals/callback/CallbackModalForm.vue';
import CallbackModalSuccess from '@/components/common/modals/callback/CallbackModalSuccess.vue';
import type { AppealType } from '@/types/Appeal';
import { APPEAL_TYPE } from '@/store/appeal';

export default defineComponent({
  name: 'CallbackModal',

  components: {
    CallbackModalForm,
    CallbackModalSuccess
  },

  model: {
    prop: 'open',
    event: 'update:open'
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: undefined
    },

    type: {
      type: String as PropType<AppealType>,
      required: true
    },

    cardTitle: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      isCallbackModalFormOpen: false,
      isCallbackModalSuccessOpen: false,
      isLoading: false
    };
  },

  computed: {
    isModalShown () {
      return !(this.isCallbackModalFormOpen || this.isCallbackModalSuccessOpen);
    },

    isFreeBooking () {
      return [
        APPEAL_TYPE.FAVORITES_FREE_BOOKING,
        APPEAL_TYPE.OFFER_FREE_BOOKING
      ].includes(this.type);
    },

    callbackModalSuccessProps () {
      return this.isFreeBooking
        ? {
          title: this.$t('callbackModalSuccessTitle') as string,
          description: this.$t('callbackModalSuccessDescription') as string,
          buttonLabel: this.$t('callbackModalSuccessButtonLabel') as string
        }
        : {
          autoClose: true
        };
    }
  },

  watch: {
    open (value) {
      this.$nextTick(() => {
        this.isCallbackModalFormOpen = value;
      });
    },

    isModalShown (value) {
      if (value) {
        this.$emit('update:open', false);
      }
    }
  },

  methods: {
    formConfirmHandler () {
      this.isCallbackModalSuccessOpen = true;
    }
  }
});
